import { type ReactNode } from 'react';
import styles from './HomeHeroContainer.module.css';

type Props = {
  children?: ReactNode;
  showPlaceholder: boolean;
};

export function HomeHeroContainer({ showPlaceholder, children }: Props) {
  if (showPlaceholder === true) {
    return (
      <div className={styles.container}>
        <div className={styles.hide}>{children}</div>
      </div>
    );
  }

  return children;
}
