import { useQuery } from '@tanstack/react-query';
import { type Quiz } from '../main';

const QUIZ_KEY = ['quiz'];

type Params = {
  enabled?: boolean;
};

export const LOCAL_STORAGE_KEY = `quiz.local`;

export function useLocalQuiz(params: Params = {}) {
  const query = useQuery({
    queryKey: [QUIZ_KEY, 'local'],
    queryFn: async () => {
      const value = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (value === null) {
        return null;
      }

      try {
        return JSON.parse(value) as Quiz;
      } catch {
        return null;
      }
    },
    ...params,
  });

  return query.data;
}
