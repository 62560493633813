import * as React from "react";
type Props = {
  className?: string,
};
function SvgFlexitarian({ className }: Props) {
  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="illustrated-Flexitarian"
      className={className}
    >
      <path
        d="M16.3234 12.2291V16.7798M13.9133 35.2481L16.8527 38.015C17.3468 38.2953 18.1446 38.6728 19.1887 38.8533C19.1887 38.8533 20.4231 39.0667 21.7841 38.8268C25.0412 38.2531 28.3943 34.5626 28.8818 34.017C31.7407 30.8168 32.8812 27.7341 33.6647 25.6168C34.9855 22.0469 34.9061 20.1196 34.6257 18.7598C34.4804 18.0551 34.3313 17.5333 34.1354 17.1391C33.5394 15.9403 32.5973 15.2543 32.0434 14.9178M19.8727 26.4304L26.4508 20.8588M16.3234 12.53C4.84383 12.6504 5.97223 1 5.97223 1C17.4355 1.4728 16.3234 12.53 16.3234 12.53ZM16.3234 12.5557C27.8029 12.6761 26.6745 1.026 26.6745 1.026C15.2699 1.4731 16.3821 12.53 16.3821 12.53L16.3234 12.5557ZM14.9509 35.6413C9.10629 32.8317 9.85949 25.0865 14.8213 22.0257C17.2782 20.5101 20.1649 20.1043 22.5928 18.459C24.9067 16.891 27.3165 12.6705 30.5904 13.7848C37.6302 16.1809 25.3686 40.6496 14.9509 35.6413ZM18.0516 31.0219C19.519 31.0219 20.7086 29.8324 20.7086 28.365C20.7086 26.8976 19.519 25.708 18.0516 25.708C16.5842 25.708 15.3947 26.8976 15.3947 28.365C15.3947 29.8324 16.5842 31.0219 18.0516 31.0219Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default SvgFlexitarian;
