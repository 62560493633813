import { ListBox, ListBoxItem } from 'react-aria-components';
import {
  Beef,
  Flexitarian,
  Pescatarian,
  Vegan,
  Vegetarian,
} from '@hungryroot/icons';
import { EatingHabit, ROUTE_HELLO } from '@hungryroot/quiz';
import { Heading, HeadingVariant } from '@hungryroot/ramen';
import styles from './DirectToQuizQuestion.module.css';

const QUIZ_PATH = `${ROUTE_HELLO}?habits=`;

export function DirectToQuizQuestion() {
  return (
    <div className={styles.container}>
      <Heading variant={HeadingVariant.H3} className={styles.heading}>
        Start by telling us how you eat:
      </Heading>
      <ListBox
        aria-label="Tell us how you eat"
        selectionMode="single"
        className={styles.list}
      >
        <ListBoxItem
          textValue="Omnivore"
          id={EatingHabit.Omnivore}
          className={styles.item}
          href={QUIZ_PATH + EatingHabit.Omnivore}
        >
          <Beef className={styles.icon} />
          <Heading variant={HeadingVariant.H6}>{EatingHabit.Omnivore}</Heading>
        </ListBoxItem>
        <ListBoxItem
          textValue="Flexitarian"
          id={EatingHabit.Flexitarian}
          className={styles.item}
          href={QUIZ_PATH + EatingHabit.Flexitarian}
        >
          <Flexitarian className={styles.icon} />
          <Heading variant={HeadingVariant.H6}>
            {EatingHabit.Flexitarian}
          </Heading>
        </ListBoxItem>
        <ListBoxItem
          textValue="Pescatarian"
          id={EatingHabit.Pescatarian}
          className={styles.item}
          href={QUIZ_PATH + EatingHabit.Pescatarian}
        >
          <Pescatarian className={styles.icon} />
          <Heading variant={HeadingVariant.H6}>
            {EatingHabit.Pescatarian}
          </Heading>
        </ListBoxItem>
        <ListBoxItem
          textValue="Vegetarian"
          id={EatingHabit.Vegetarian}
          className={styles.item}
          href={QUIZ_PATH + EatingHabit.Vegetarian}
        >
          <Vegetarian className={styles.icon} />
          <Heading variant={HeadingVariant.H6}>
            {EatingHabit.Vegetarian}
          </Heading>
        </ListBoxItem>
        <ListBoxItem
          textValue="Vegan"
          id={EatingHabit.Vegan}
          className={styles.item}
          href={QUIZ_PATH + EatingHabit.Vegan}
        >
          <Vegan className={styles.icon} />
          <Heading variant={HeadingVariant.H6}>{EatingHabit.Vegan}</Heading>
        </ListBoxItem>
      </ListBox>
    </div>
  );
}
