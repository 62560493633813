import { type ComponentProps } from 'react';
import { AuthState, useIdentify } from '@hungryroot/customer';
import {
  type ExperimentVariants,
  useExperiment,
} from '@hungryroot/experiments';
import { useLocalQuiz } from '@hungryroot/quiz';
import { DefaultHomeHero, type HomeHeroProps } from './DefaultHomeHero';
import { DirectToQuizQuestion } from './Exp_157/DirectToQuizQuestion/DirectToQuizQuestion';
import { HomeHeroContainer } from './Exp_157/HomeHeroContainer/HomeHeroContainer';
import { StaticHomeHero } from './Exp_157/StaticHomeHero/StaticHomeHero';
import styles from './DefaultHomeHero.module.css';

type StaticHomeHeroProps = ComponentProps<typeof StaticHomeHero>;

export const homeHeroCta = styles.button;

const HEADING_COPY = 'Personalized meal plans + groceries';

function shouldShowDirectToQuizQuestion({
  authState,
  localStorageQuiz,
  variant,
}: {
  variant: ExperimentVariants<157> | undefined;
  authState: AuthState;
  localStorageQuiz: ReturnType<typeof useLocalQuiz>;
}) {
  if (variant !== 2 || variant === undefined) {
    return false;
  }

  const isAuthenticated = authState === AuthState.isAuthenticated;
  const isQuizInLocalStorage =
    authState === AuthState.isAnonymous &&
    localStorageQuiz !== null &&
    localStorageQuiz !== undefined;

  return isAuthenticated === false && isQuizInLocalStorage === false;
}

export function HomeHero(props: HomeHeroProps) {
  const variant = useExperiment(157);

  const customer = useIdentify();
  const localStorageQuery = useLocalQuiz({
    enabled: variant === 2 && customer.authState === AuthState.isAnonymous,
  });

  const showDirectToQuizQuestion = shouldShowDirectToQuizQuestion({
    variant,
    localStorageQuiz: localStorageQuery,
    authState: customer.authState,
  });
  const staticHeroProps: StaticHomeHeroProps = {
    ...props,
    heading: HEADING_COPY,
    directToQuizQuestion:
      showDirectToQuizQuestion === true ? <DirectToQuizQuestion /> : undefined,
  };

  return (
    <HomeHeroContainer showPlaceholder={variant === undefined}>
      {(variant === undefined || variant < 1) && <DefaultHomeHero {...props} />}
      {(variant === undefined || variant > 0) && (
        <StaticHomeHero {...staticHeroProps} />
      )}
    </HomeHeroContainer>
  );
}
